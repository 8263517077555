{
  "_format": "hh-sol-artifact-1",
  "contractName": "CrossDomainEnabled",
  "sourceName": "contracts/libraries/bridge/CrossDomainEnabled.sol",
  "abi": [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_messenger",
          "type": "address"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "inputs": [],
      "name": "messenger",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ],
  "bytecode": "0x608060405234801561001057600080fd5b5060405161013d38038061013d83398101604081905261002f91610054565b600080546001600160a01b0319166001600160a01b0392909216919091179055610084565b60006020828403121561006657600080fd5b81516001600160a01b038116811461007d57600080fd5b9392505050565b60ab806100926000396000f3fe6080604052348015600f57600080fd5b506004361060285760003560e01c80633cb747bf14602d575b600080fd5b600054604c9073ffffffffffffffffffffffffffffffffffffffff1681565b60405173ffffffffffffffffffffffffffffffffffffffff909116815260200160405180910390f3fea264697066735822122007712a382b03013a51c1c5af10d9e7b9b516e09be353a5f94af5d0263a013ea064736f6c63430008090033",
  "deployedBytecode": "0x6080604052348015600f57600080fd5b506004361060285760003560e01c80633cb747bf14602d575b600080fd5b600054604c9073ffffffffffffffffffffffffffffffffffffffff1681565b60405173ffffffffffffffffffffffffffffffffffffffff909116815260200160405180910390f3fea264697066735822122007712a382b03013a51c1c5af10d9e7b9b516e09be353a5f94af5d0263a013ea064736f6c63430008090033",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
