import React from 'react'
import { Button } from "@/components/ui/button"
import { Link } from "react-router-dom";
import { useAccount } from 'wagmi';

export default function ChainSelection() {
    const { isConnected } = useAccount()

    return (
        <div className="space-y-4">
            <Link to="/goerlibase"><Button className='w-full' disabled={!isConnected}>Base + Goerli</Button></Link>
        </div>
    );
}