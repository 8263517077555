import { useState, useEffect } from 'react';
import { useContractWrite, useAccount, useWaitForTransaction } from 'wagmi';
import * as optimismContracts from "@eth-optimism/contracts-bedrock"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { useParams, useNavigate } from 'react-router-dom';
import { useL2, type L2 } from '../hooks/useL2';
import TxStatus from './TxStatus';
import Loading from "../common/Loading"


export default function StartTransaction(): React.ReactElement {
    const { l2Id } = useParams<{ l2Id: string }>()
    const { config } = useL2({ l2Id })
    const navigate = useNavigate();

    if (!config) { return <>{l2Id} is not supported</> }

    return (
        <StartTransactionForL2
            l2={config}
            onSuccess={(hash) => navigate(hash)}
        />
    )
}

function StartTransactionForL2({ l2, onSuccess }: { l2: L2, onSuccess: (hash: string) => void }) {
    const [value, setValue] = useState<number>(0.01)

    const depositTransaction = useContractWrite({
        address: l2.contracts.OptimismPortal,
        abi: optimismContracts.getContractDefinition("OptimismPortal").abi,
        functionName: 'depositTransaction',
        chainId: l2.l1.id
    })

    const handleDeposit = async () => {
        depositTransaction.write({
            args: [optimismContracts.predeploys.L2StandardBridge, BigInt(value * 1e18), 1e6, false, ""]
        })
    }

    const l1ChainTx = useWaitForTransaction(depositTransaction.data)

    useEffect(() => {
        if (!l1ChainTx.data?.transactionHash) { return }
        onSuccess(l1ChainTx.data.transactionHash)
    }, [l1ChainTx])


    const handleChangeValue = (value: string) => {
        const floatValue = parseFloat(value)
        if (!isNaN(floatValue)) {
            setValue(floatValue)
        }
    }
    return (
        <>
            <Input value={value} type="number" placeholder="0.01" className='w-full' onChange={(e) => handleChangeValue(e.target.value)} />

            <Button className="w-full" disabled={value <= 0 || depositTransaction.isLoading || l1ChainTx.isLoading} onClick={handleDeposit}>Withdraw {value}  <Loading className="ml-2" active={depositTransaction.isLoading || l1ChainTx.isLoading} /></Button>
            {depositTransaction.isError && <div className="font-bold text-red-700">{depositTransaction.error?.message}</div>}
            {l1ChainTx.isError && <div className="font-bold text-red-700">{l1ChainTx.error?.message}</div>}

            {depositTransaction.data?.hash &&
                <TxStatus
                    txId={depositTransaction.data?.hash}
                    chainId={l2?.l1.id}
                    successElement={<>Withdrawal Initialized</>}
                />}
        </>
    )
}

