import AppRouter from "./AppRouter";

import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { goerli, mainnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "@/components/ui/toaster"


const queryClient = new QueryClient()
const { chains, publicClient } = configureChains(
    [goerli, mainnet],
    [publicProvider()]
);

const { connectors } = getDefaultWallets({
    appName: 'Forced Inclusion',
    projectId: 'YOUR_PROJECT_ID',
    chains
});

const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
})

export default function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <WagmiConfig config={wagmiConfig}>
                <RainbowKitProvider chains={chains}>
                    <BrowserRouter future={{ v7_startTransition: true }}>
                        <AppRouter />
                        <Toaster />
                    </BrowserRouter>
                </RainbowKitProvider>
            </WagmiConfig>
        </QueryClientProvider>
    );
}