import { useCallback, useState, useEffect } from 'react'
import * as optimismSDK from "@eth-optimism/sdk"
import * as optimismContracts from "@eth-optimism/contracts-bedrock"
import * as optimismCoreUtils from "@eth-optimism/core-utils"
import { useIsMounted, useInterval } from 'usehooks-ts'
import { useL2 } from './useL2'
import { useWaitForTransaction, useContractEvent } from 'wagmi'
import { decodeFunctionData } from "viem"

type HashStatus = {
    hash: string
    statusCode: number
    statusText: string
    isLoading: boolean
    isError: boolean
    error?: {
        message: string
    }
}

const MessageStatus = optimismSDK.MessageStatus
export { MessageStatus }

const AUTO_REFRESH_INTERVAL_SECONDS = 30
export function useStatus({ l2Id, txId }: { l2Id?: string, txId?: string } = {}): HashStatus {
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [statusCode, setStatusCode] = useState(0)
    const [statusText, setStatusText] = useState('')
    const [hash, setHash] = useState('')
    const { config, messenger, l1Client } = useL2({ l2Id })
    const isMounted = useIsMounted()
    const tx = useWaitForTransaction(txId ? { hash: txId as `0x{string}` } : undefined)

    useEffect(() => {
        if (!tx.data || !tx.data.logs[0] || !l1Client) { return }

        void (async (txData) => {
            const rawTransaction = await l1Client.getTransaction({
                hash: txData.transactionHash
            })

            const { args } = decodeFunctionData({
                abi: optimismContracts.getContractDefinition("OptimismPortal").abi,
                data: rawTransaction.input
            })


            const opts = {
                from: txData.from,
                to: optimismContracts.predeploys.L2StandardBridge,
                mint: 0,
                value: args[1],
                gas: 1e6,
                isSystemTransaction: false,
                data: "",
                domain: optimismCoreUtils.SourceHashDomain.UserDeposit,
                l1BlockHash: txData.blockHash,
                logIndex: txData.logs[0]!.logIndex,
            }

            const depositTxData = new optimismCoreUtils.DepositTx(opts)
            const hash = depositTxData.hash()
            setHash(hash)
        })(tx.data)

    }, [l2Id, tx, l1Client])

    const updateStatus = useCallback(async () => {
        if (!messenger || !hash || !l1Client) { return }
        if (statusCode === optimismSDK.MessageStatus.RELAYED) { return }

        try {
            setIsLoading(true)
            setErrorMessage('')

            const statusCode = await messenger.getMessageStatus(hash)
            const receipt = await messenger.getMessageReceipt(hash)

            const logs = await l1Client.getContractEvents({
                address: config?.contracts.OptimismPortal,
                abi: optimismContracts.getContractDefinition("OptimismPortal").abi,
                eventName: 'WithdrawalFinalized',
                args: {
                    withdrawalHash: hash
                }
            })
            console.log(hash, statusCode, receipt, logs)
            setStatusCode(statusCode)
        }
        catch (err) {
            const errorMessage = err instanceof Error ? String(err.message) : String(err)
            setErrorMessage(errorMessage)
            console.error(err)
        }
        finally {
            setIsLoading(false)
        }

    }, [messenger, hash, l1Client])


    useEffect(() => {
        if (isMounted()) { void updateStatus() }
    }, [isMounted, updateStatus])
    useInterval(() => { void updateStatus() }, 1000 * AUTO_REFRESH_INTERVAL_SECONDS)

    useEffect(() => {
        if (optimismSDK.MessageStatus.READY_FOR_RELAY === statusCode) {
            setStatusText('Ready for relay')
        }
        else if (optimismSDK.MessageStatus.READY_TO_PROVE === statusCode) {
            setStatusText('Ready to prove')
        }
        else if (optimismSDK.MessageStatus.IN_CHALLENGE_PERIOD === statusCode) {
            if (!messenger) {
                setStatusText(`In Challenge Period`)
            }
            else {
                messenger.getChallengePeriodSeconds()
                    .then(seconds => {
                        setStatusText(`In Challenge Period (${seconds}s)`)
                    })
            }
        }
        else if (optimismSDK.MessageStatus.RELAYED === statusCode) {
            setStatusText('Relayed')
        }
        else if (optimismSDK.MessageStatus.STATE_ROOT_NOT_PUBLISHED === statusCode) {
            setStatusText('Waiting for L2 inclusion, this could take a few minutes')
        }
        else if (optimismSDK.MessageStatus.FAILED_L1_TO_L2_MESSAGE === statusCode) {
            setStatusText('FAILED_L1_TO_L2_MESSAGE')
        }
        else if (optimismSDK.MessageStatus.UNCONFIRMED_L1_TO_L2_MESSAGE === statusCode) {
            setStatusText('Found L1 transactions, waiting for L2 confirmation')
        }
        else {
            setStatusText(`Status: #${statusCode}`)
        }
    }, [statusCode, messenger])


    if (!messenger) {
        return {
            hash,
            statusCode: 0,
            statusText: '',
            isLoading: false,
            isError: true,
            error: {
                message: 'Invalid network'
            }
        }
    }


    return {
        hash,
        statusCode,
        statusText,
        isLoading,
        isError,
        error: isError ? { message: errorMessage } : undefined
    }
}
