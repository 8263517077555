{
  "_format": "hh-sol-artifact-1",
  "contractName": "Lib_RLPWriter",
  "sourceName": "contracts/libraries/rlp/Lib_RLPWriter.sol",
  "abi": [],
  "bytecode": "0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea2646970667358221220946ae63101b895e34c026fce9aa6f03d577c9012d8837976e8dc3a57ae0a2ef064736f6c63430008090033",
  "deployedBytecode": "0x73000000000000000000000000000000000000000030146080604052600080fdfea2646970667358221220946ae63101b895e34c026fce9aa6f03d577c9012d8837976e8dc3a57ae0a2ef064736f6c63430008090033",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
