{
  "_format": "hh-sol-artifact-1",
  "contractName": "iOVM_L2ToL1MessagePasser",
  "sourceName": "contracts/L2/predeploys/iOVM_L2ToL1MessagePasser.sol",
  "abi": [
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_nonce",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "address",
          "name": "_sender",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "bytes",
          "name": "_data",
          "type": "bytes"
        }
      ],
      "name": "L2ToL1Message",
      "type": "event"
    },
    {
      "inputs": [
        {
          "internalType": "bytes",
          "name": "_message",
          "type": "bytes"
        }
      ],
      "name": "passMessageToL1",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ],
  "bytecode": "0x",
  "deployedBytecode": "0x",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
