import React from 'react'

import Auth from './ui/Auth'
import StartTransaction from './ui/StartTransaction'
import Status from './ui/Status'
import { Routes, Route } from "react-router-dom";

import ChainSelection from './ui/ChainSelection'

export default function UI() {

    return (
        <div className="w-full h-full flex justify-center p-4 sm:p-0">
            <div className="space-y-4 mt-4 max-w-2xl w-full">
                <div className="flex justify-center mb-16">
                    <Auth />
                </div>

                <Routes>
                    <Route
                        path="/:l2Id"
                        element={<StartTransaction />}
                    />

                    <Route
                        path="/:l2Id/:txId"
                        element={<Status />}
                    />

                    <Route path="*" element={<ChainSelection />} />
                </Routes>


            </div >
        </div>
    );
}