{
  "_format": "hh-sol-artifact-1",
  "contractName": "ResourceMetering",
  "sourceName": "contracts/L1/ResourceMetering.sol",
  "abi": [
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint8",
          "name": "version",
          "type": "uint8"
        }
      ],
      "name": "Initialized",
      "type": "event"
    },
    {
      "inputs": [],
      "name": "params",
      "outputs": [
        {
          "internalType": "uint128",
          "name": "prevBaseFee",
          "type": "uint128"
        },
        {
          "internalType": "uint64",
          "name": "prevBoughtGas",
          "type": "uint64"
        },
        {
          "internalType": "uint64",
          "name": "prevBlockNum",
          "type": "uint64"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ],
  "bytecode": "0x",
  "deployedBytecode": "0x",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
