{
  "_format": "hh-sol-artifact-1",
  "contractName": "EchidnaFuzzEncoding",
  "sourceName": "contracts/echidna/FuzzEncoding.sol",
  "abi": [
    {
      "inputs": [],
      "name": "echidna_round_trip_encoding_AToB",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "echidna_round_trip_encoding_BToA",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint240",
          "name": "_nonce",
          "type": "uint240"
        },
        {
          "internalType": "uint16",
          "name": "_version",
          "type": "uint16"
        }
      ],
      "name": "testRoundTripAToB",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_versionedNonce",
          "type": "uint256"
        }
      ],
      "name": "testRoundTripBToA",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ],
  "bytecode": "0x608060405234801561001057600080fd5b5061023d806100206000396000f3fe608060405234801561001057600080fd5b506004361061004c5760003560e01c806328f9acd8146100515780633149fcfb14610066578063a6138b0414610082578063e823d02314610093575b600080fd5b61006461005f3660046101b4565b6100a6565b005b60005460ff16155b604051901515815260200160405180910390f35b600054610100900460ff161561006e565b6100646100a13660046101cd565b61012b565b7dffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff811660f082901c7fffff0000000000000000000000000000000000000000000000000000000000008316821780841461012557600080547fffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff00ff166101001790555b50505050565b6000610139838360f01b1790565b90507dffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff8082169060f083901c9085168214158061017c57508361ffff168161ffff1614155b156101ad57600080547fffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff001660011790555b5050505050565b6000602082840312156101c657600080fd5b5035919050565b600080604083850312156101e057600080fd5b82357dffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff8116811461020e57600080fd5b9150602083013561ffff8116811461022557600080fd5b80915050925092905056fea164736f6c634300080f000a",
  "deployedBytecode": "0x608060405234801561001057600080fd5b506004361061004c5760003560e01c806328f9acd8146100515780633149fcfb14610066578063a6138b0414610082578063e823d02314610093575b600080fd5b61006461005f3660046101b4565b6100a6565b005b60005460ff16155b604051901515815260200160405180910390f35b600054610100900460ff161561006e565b6100646100a13660046101cd565b61012b565b7dffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff811660f082901c7fffff0000000000000000000000000000000000000000000000000000000000008316821780841461012557600080547fffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff00ff166101001790555b50505050565b6000610139838360f01b1790565b90507dffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff8082169060f083901c9085168214158061017c57508361ffff168161ffff1614155b156101ad57600080547fffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff001660011790555b5050505050565b6000602082840312156101c657600080fd5b5035919050565b600080604083850312156101e057600080fd5b82357dffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff8116811461020e57600080fd5b9150602083013561ffff8116811461022557600080fd5b80915050925092905056fea164736f6c634300080f000a",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
