{
  "_format": "hh-sol-artifact-1",
  "contractName": "CrossDomainMessengerLegacySpacer1",
  "sourceName": "contracts/universal/CrossDomainMessenger.sol",
  "abi": [],
  "bytecode": "0x6080604052348015600f57600080fd5b50601680601d6000396000f3fe6080604052600080fdfea164736f6c634300080f000a",
  "deployedBytecode": "0x6080604052600080fdfea164736f6c634300080f000a",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
