{
  "_format": "hh-sol-artifact-1",
  "contractName": "Lib_BytesUtils",
  "sourceName": "contracts/libraries/utils/Lib_BytesUtils.sol",
  "abi": [],
  "bytecode": "0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea264697066735822122017e44b43285912c440c4689616a765b1f83f985ef6c2257842a876b03fa23ec964736f6c63430008090033",
  "deployedBytecode": "0x73000000000000000000000000000000000000000030146080604052600080fdfea264697066735822122017e44b43285912c440c4689616a765b1f83f985ef6c2257842a876b03fa23ec964736f6c63430008090033",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
