{
  "_format": "hh-sol-artifact-1",
  "contractName": "IChainStorageContainer",
  "sourceName": "contracts/L1/rollup/IChainStorageContainer.sol",
  "abi": [
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_index",
          "type": "uint256"
        },
        {
          "internalType": "bytes27",
          "name": "_globalMetadata",
          "type": "bytes27"
        }
      ],
      "name": "deleteElementsAfterInclusive",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_index",
          "type": "uint256"
        }
      ],
      "name": "deleteElementsAfterInclusive",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_index",
          "type": "uint256"
        }
      ],
      "name": "get",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getGlobalMetadata",
      "outputs": [
        {
          "internalType": "bytes27",
          "name": "",
          "type": "bytes27"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "length",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "_object",
          "type": "bytes32"
        },
        {
          "internalType": "bytes27",
          "name": "_globalMetadata",
          "type": "bytes27"
        }
      ],
      "name": "push",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "_object",
          "type": "bytes32"
        }
      ],
      "name": "push",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes27",
          "name": "_globalMetadata",
          "type": "bytes27"
        }
      ],
      "name": "setGlobalMetadata",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ],
  "bytecode": "0x",
  "deployedBytecode": "0x",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
