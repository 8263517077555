{
  "_format": "hh-sol-artifact-1",
  "contractName": "AddressAliasHelper",
  "sourceName": "contracts/standards/AddressAliasHelper.sol",
  "abi": [],
  "bytecode": "0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea26469706673582212207829434f027696874d561ec0adb447d98559e472900c36a38c8a3d107364652364736f6c63430008090033",
  "deployedBytecode": "0x73000000000000000000000000000000000000000030146080604052600080fdfea26469706673582212207829434f027696874d561ec0adb447d98559e472900c36a38c8a3d107364652364736f6c63430008090033",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
