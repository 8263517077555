{
  "_format": "hh-sol-artifact-1",
  "contractName": "Lib_DefaultValues",
  "sourceName": "contracts/libraries/constants/Lib_DefaultValues.sol",
  "abi": [],
  "bytecode": "0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea2646970667358221220063c5ffd8afe3ee3c085661f8b942ed207ae0c52f76c705ce32343804219a1be64736f6c63430008090033",
  "deployedBytecode": "0x73000000000000000000000000000000000000000030146080604052600080fdfea2646970667358221220063c5ffd8afe3ee3c085661f8b942ed207ae0c52f76c705ce32343804219a1be64736f6c63430008090033",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
