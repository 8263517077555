import * as React from 'react'
import { Networks, type L2 } from '../config/Networks'
import * as optimismSDK from "@eth-optimism/sdk"
import { usePublicClient, type PublicClient } from 'wagmi'
import { ethers } from 'ethers'


import { useEthersSigner } from './useEthersSigner'

type L2Config = {
    config?: L2
    messenger?: optimismSDK.CrossChainMessenger
    l1Client?: PublicClient
}

export { L2 }

export function useL2({ l2Id }: { l2Id?: string } = {}): L2Config {
    if (!l2Id || !Networks[l2Id]) return {}

    const l2 = Networks[l2Id]!
    const l1Signer = useEthersSigner({ chainId: l2.l1.id })
    const l1Client = usePublicClient({ chainId: l2.l1.id })

    return React.useMemo(() => {
        if (!l1Signer) return { config: l2 }

        const opts = {
            l1ChainId: l2.l1.id,
            l2ChainId: l2.id,
            l1SignerOrProvider: l1Signer,
            l2SignerOrProvider: new ethers.providers.JsonRpcProvider(l2.rpcUrl),
            bedrock: true,
            contracts: {
                l1: l2.contracts
            },
            bridges: l2.bridges
        }

        const crossChainMessenger = new optimismSDK.CrossChainMessenger(opts)

        return {
            l1Client,
            config: l2,
            messenger: crossChainMessenger
        }
    }, [l2Id, l1Signer, l1Client])
}
