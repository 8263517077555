{
  "_format": "hh-sol-artifact-1",
  "contractName": "Burner",
  "sourceName": "contracts/libraries/Burn.sol",
  "abi": [
    {
      "inputs": [],
      "stateMutability": "payable",
      "type": "constructor"
    }
  ],
  "bytecode": "0x608060405230fffe",
  "deployedBytecode": "0x6080604052600080fdfea164736f6c634300080f000a",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
