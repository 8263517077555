{
  "_format": "hh-sol-artifact-1",
  "contractName": "PortalSender",
  "sourceName": "contracts/deployment/PortalSender.sol",
  "abi": [
    {
      "inputs": [
        {
          "internalType": "contract OptimismPortal",
          "name": "_portal",
          "type": "address"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "inputs": [],
      "name": "PORTAL",
      "outputs": [
        {
          "internalType": "contract OptimismPortal",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "donate",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ],
  "bytecode": "0x60a060405234801561001057600080fd5b506040516101b53803806101b583398101604081905261002f91610040565b6001600160a01b0316608052610070565b60006020828403121561005257600080fd5b81516001600160a01b038116811461006957600080fd5b9392505050565b6080516101256100906000396000818160400152609701526101256000f3fe608060405234801561001057600080fd5b50600436106100365760003560e01c80630ff754ea1461003b578063ed88c68e1461008b575b600080fd5b6100627f000000000000000000000000000000000000000000000000000000000000000081565b60405173ffffffffffffffffffffffffffffffffffffffff909116815260200160405180910390f35b610093610095565b005b7f000000000000000000000000000000000000000000000000000000000000000073ffffffffffffffffffffffffffffffffffffffff16638b4c40b0476040518263ffffffff1660e01b81526004016000604051808303818588803b1580156100fd57600080fd5b505af1158015610111573d6000803e3d6000fd5b505050505056fea164736f6c634300080f000a",
  "deployedBytecode": "0x608060405234801561001057600080fd5b50600436106100365760003560e01c80630ff754ea1461003b578063ed88c68e1461008b575b600080fd5b6100627f000000000000000000000000000000000000000000000000000000000000000081565b60405173ffffffffffffffffffffffffffffffffffffffff909116815260200160405180910390f35b610093610095565b005b7f000000000000000000000000000000000000000000000000000000000000000073ffffffffffffffffffffffffffffffffffffffff16638b4c40b0476040518263ffffffff1660e01b81526004016000604051808303818588803b1580156100fd57600080fd5b505af1158015610111573d6000803e3d6000fd5b505050505056fea164736f6c634300080f000a",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
