{
  "_format": "hh-sol-artifact-1",
  "contractName": "Lib_PredeployAddresses",
  "sourceName": "contracts/libraries/constants/Lib_PredeployAddresses.sol",
  "abi": [],
  "bytecode": "0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea264697066735822122056cad517f08aabd21cc0b53f664298b5b8af8f91610a5d788b7cd5a14d12078b64736f6c63430008090033",
  "deployedBytecode": "0x73000000000000000000000000000000000000000030146080604052600080fdfea264697066735822122056cad517f08aabd21cc0b53f664298b5b8af8f91610a5d788b7cd5a14d12078b64736f6c63430008090033",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
