{
  "_format": "hh-sol-artifact-1",
  "contractName": "IL1ChugSplashDeployer",
  "sourceName": "contracts/legacy/L1ChugSplashProxy.sol",
  "abi": [
    {
      "inputs": [],
      "name": "isUpgrading",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ],
  "bytecode": "0x",
  "deployedBytecode": "0x",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
