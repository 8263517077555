import { useParams } from "react-router-dom"
import { useStatus, MessageStatus } from '../hooks/useStatus'
import ProveWithdrawal from './ProveWithdrawal'
import FinalizeWithdrawal from "./FinalizeWithdrawal"
import Loading from "../common/Loading"

export default function Status() {
    const { l2Id, txId } = useParams<{ l2Id: string, txId: string }>()
    const status = useStatus({ l2Id, txId })

    if (!l2Id || !txId) {
        return <>Missing L2 details or txId</>
    }

    return (
        <div className="space-y-8">
            {status.statusCode === MessageStatus.READY_TO_PROVE && <ProveWithdrawal l2Id={l2Id} hash={status.hash} />}
            {status.statusCode === MessageStatus.READY_FOR_RELAY && <FinalizeWithdrawal l2Id={l2Id} hash={status.hash} />}

            <div className="space-x-2 flex items-center justify-center">
                <span className="font-bold">Status:</span> <span>{status.statusText}</span> <Loading active={status.isLoading} />
            </div>
        
            {status.isError && <div className="font-bold text-red-700">{status.error?.message}</div>}
        </div>
    )
}

