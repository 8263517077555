{
  "_format": "hh-sol-artifact-1",
  "contractName": "Lib_AddressResolver",
  "sourceName": "contracts/libraries/resolver/Lib_AddressResolver.sol",
  "abi": [
    {
      "inputs": [],
      "name": "libAddressManager",
      "outputs": [
        {
          "internalType": "contract Lib_AddressManager",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "_name",
          "type": "string"
        }
      ],
      "name": "resolve",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ],
  "bytecode": "0x",
  "deployedBytecode": "0x",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
