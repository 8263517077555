{
  "_format": "hh-sol-artifact-1",
  "contractName": "Lib_MerkleTrie",
  "sourceName": "contracts/libraries/trie/Lib_MerkleTrie.sol",
  "abi": [],
  "bytecode": "0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea2646970667358221220214650a5ec3e1d09ab69aa832632dcca9858b098070c9e81cdae9075c0e89eef64736f6c63430008090033",
  "deployedBytecode": "0x73000000000000000000000000000000000000000030146080604052600080fdfea2646970667358221220214650a5ec3e1d09ab69aa832632dcca9858b098070c9e81cdae9075c0e89eef64736f6c63430008090033",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
