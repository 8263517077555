import { useEffect } from 'react';
import { useWaitForTransaction } from 'wagmi';
import { CopyIcon } from '@radix-ui/react-icons'
import { useCopyToClipboard } from 'usehooks-ts'
import { useToast } from "@/components/ui/use-toast"
import Loading from '../common/Loading';

type Props = {
    txId: string
    chainId?: number
    successElement?: React.ReactElement
}

export default function TxStatus({ txId, chainId, successElement }: Props): React.ReactElement {
    const tx = useWaitForTransaction({ hash: txId as `0x{string}`, chainId })

    const [copiedTxId, copyTxId] = useCopyToClipboard()
    const { toast } = useToast()

    useEffect(() => { Boolean(copiedTxId) && toast({ description: `Copied TxId: ${txId}` }) }, [copiedTxId, toast, txId])


    return (
        <dl className="divide-y divide-gray-100">
            <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-light leading-6">Transaction Id</dt>
                <dd className="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0 flex space-x-2 items-baseline sm:justify-end pb-4 sm:pb-0">
                    <code>{(tx.data?.transactionHash ?? txId).slice(0,16)}..{(tx.data?.transactionHash ?? txId).slice(-16)}</code>
                    <CopyIcon className="cursor-pointer" onClick={() => copyTxId(tx.data?.transactionHash ?? txId)} />
                </dd>

                <dt className="text-sm font-light leading-6">Status</dt>
                <dd className="mt-1 text-sm leading-6  sm:col-span-2 sm:mt-0 flex space-x-2 items-baseline sm:justify-end pb-4 sm:pb-0">
                    {tx.data?.status === 'success' ? successElement : <Loading active />}
                </dd>
            </div>
        </dl>
    )
}

