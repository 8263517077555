{
  "_format": "hh-sol-artifact-1",
  "contractName": "Lib_Buffer",
  "sourceName": "contracts/libraries/utils/Lib_Buffer.sol",
  "abi": [],
  "bytecode": "0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea2646970667358221220594b214017d57365da3ea1c77083baf56a0e2bbeb0c3e002e311ad4566a5a88e64736f6c63430008090033",
  "deployedBytecode": "0x73000000000000000000000000000000000000000030146080604052600080fdfea2646970667358221220594b214017d57365da3ea1c77083baf56a0e2bbeb0c3e002e311ad4566a5a88e64736f6c63430008090033",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
