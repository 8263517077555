{
  "_format": "hh-sol-artifact-1",
  "contractName": "MerkleTrie",
  "sourceName": "contracts/libraries/trie/MerkleTrie.sol",
  "abi": [],
  "bytecode": "0x602d6037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea164736f6c634300080f000a",
  "deployedBytecode": "0x73000000000000000000000000000000000000000030146080604052600080fdfea164736f6c634300080f000a",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
