{
  "_format": "hh-sol-artifact-1",
  "contractName": "Lib_RLPReader",
  "sourceName": "contracts/libraries/rlp/Lib_RLPReader.sol",
  "abi": [],
  "bytecode": "0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea26469706673582212209b0105ac3644e4fdf76d616b816e1142d8df6b59717045de630c5b5bf5c4a49e64736f6c63430008090033",
  "deployedBytecode": "0x73000000000000000000000000000000000000000030146080604052600080fdfea26469706673582212209b0105ac3644e4fdf76d616b816e1142d8df6b59717045de630c5b5bf5c4a49e64736f6c63430008090033",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
