import { useState } from 'react';
import { Button } from "@/components/ui/button"
import { BaseError } from 'viem';
import { useL2 } from '../hooks/useL2';
import type { TransactionResponse } from '@ethersproject/providers';
import Loading from "../common/Loading"
import TxStatus from './TxStatus';

type Props = {
    l2Id: string
    hash: string
}

export default function FinalizeWithdrawal({ l2Id, hash }: Props): React.ReactElement {
    const { config, messenger } = useL2({ l2Id })
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [tx, setTx] = useState<TransactionResponse | undefined>()

    const handleFinalizeWithdrawal = async () => {
        if (!messenger) { return }

        try {
            setIsLoading(true)
            setErrorMessage('')


            const tx = await messenger.finalizeMessage(hash)
            setTx(tx)
        }
        catch (err) {
            const errorMessage =
                err instanceof (BaseError) ? err.shortMessage :
                    err instanceof (Error) ?
                        ('reason' in err ? String(err.reason) : err.message)
                        : String(err)
            setErrorMessage(errorMessage)
            console.error(err)
        }
        finally {
            setIsLoading(false)
        }
    }


    return (
        <>
            <Button className="w-full" disabled={isLoading || Boolean(tx)} onClick={handleFinalizeWithdrawal}>Finalize Withdrawal <Loading className="ml-2" active={isLoading} /></Button>
            {Boolean(errorMessage) && errorMessage}

            {tx !== undefined &&
                <TxStatus
                    txId={tx.hash}
                    chainId={config?.l1.id}
                    successElement={<>Withdrawal Finalized</>}
                />}
        </>
    )
}

