{
  "_format": "hh-sol-artifact-1",
  "contractName": "Lib_SecureMerkleTrie",
  "sourceName": "contracts/libraries/trie/Lib_SecureMerkleTrie.sol",
  "abi": [],
  "bytecode": "0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea26469706673582212202660de7bb8aa0cc344e5d40ecf3a0bb218888dff6bd046588cf63c7f310cc10f64736f6c63430008090033",
  "deployedBytecode": "0x73000000000000000000000000000000000000000030146080604052600080fdfea26469706673582212202660de7bb8aa0cc344e5d40ecf3a0bb218888dff6bd046588cf63c7f310cc10f64736f6c63430008090033",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
