{
  "_format": "hh-sol-artifact-1",
  "contractName": "iOVM_L1BlockNumber",
  "sourceName": "contracts/L2/predeploys/iOVM_L1BlockNumber.sol",
  "abi": [
    {
      "inputs": [],
      "name": "getL1BlockNumber",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ],
  "bytecode": "0x",
  "deployedBytecode": "0x",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
