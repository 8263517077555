{
  "_format": "hh-sol-artifact-1",
  "contractName": "EchidnaFuzzAddressAliasing",
  "sourceName": "contracts/echidna/FuzzAddressAliasing.sol",
  "abi": [
    {
      "inputs": [],
      "name": "echidna_round_trip_aliasing",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "addr",
          "type": "address"
        }
      ],
      "name": "testRoundTrip",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ],
  "bytecode": "0x608060405234801561001057600080fd5b5060aa8061001f6000396000f3fe6080604052348015600f57600080fd5b506004361060325760003560e01c8063206a6a72146037578063cfae1588146052575b600080fd5b60005460ff1615604051901515815260200160405180910390f35b6060605d3660046062565b50565b005b600060208284031215607357600080fd5b813573ffffffffffffffffffffffffffffffffffffffff81168114609657600080fd5b939250505056fea164736f6c634300080f000a",
  "deployedBytecode": "0x6080604052348015600f57600080fd5b506004361060325760003560e01c8063206a6a72146037578063cfae1588146052575b600080fd5b60005460ff1615604051901515815260200160405180910390f35b6060605d3660046062565b50565b005b600060208284031215607357600080fd5b813573ffffffffffffffffffffffffffffffffffffffff81168114609657600080fd5b939250505056fea164736f6c634300080f000a",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
