{
  "_format": "hh-sol-artifact-1",
  "contractName": "Lib_OVMCodec",
  "sourceName": "contracts/libraries/codec/Lib_OVMCodec.sol",
  "abi": [],
  "bytecode": "0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea26469706673582212200c2a5d11f9555d16a4e338a80443d30b1ec346c6a32a3a2423851829c61a564664736f6c63430008090033",
  "deployedBytecode": "0x73000000000000000000000000000000000000000030146080604052600080fdfea26469706673582212200c2a5d11f9555d16a4e338a80443d30b1ec346c6a32a3a2423851829c61a564664736f6c63430008090033",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
