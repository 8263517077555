{
  "_format": "hh-sol-artifact-1",
  "contractName": "Lib_Bytes32Utils",
  "sourceName": "contracts/libraries/utils/Lib_Bytes32Utils.sol",
  "abi": [],
  "bytecode": "0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea264697066735822122016d3dffa07e555b0803ae61d773778a6b28a769ef91e47cb00040ecc7414705764736f6c63430008090033",
  "deployedBytecode": "0x73000000000000000000000000000000000000000030146080604052600080fdfea264697066735822122016d3dffa07e555b0803ae61d773778a6b28a769ef91e47cb00040ecc7414705764736f6c63430008090033",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
