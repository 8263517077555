{
  "_format": "hh-sol-artifact-1",
  "contractName": "Lib_CrossDomainUtils",
  "sourceName": "contracts/libraries/bridge/Lib_CrossDomainUtils.sol",
  "abi": [],
  "bytecode": "0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea264697066735822122060e64a9ccca3ea023c0ba81dbda8783559d3eec6a4d1d8ef8418badc967c072664736f6c63430008090033",
  "deployedBytecode": "0x73000000000000000000000000000000000000000030146080604052600080fdfea264697066735822122060e64a9ccca3ea023c0ba81dbda8783559d3eec6a4d1d8ef8418badc967c072664736f6c63430008090033",
  "linkReferences": {},
  "deployedLinkReferences": {}
}
