import { baseGoerli, goerli } from 'viem/chains';
import * as optimismSDK from "@eth-optimism/sdk"

export type L1 = {
    id: number
}

export type L2 = {
    id: number,
    rpcUrl: string,
    l1: L1,
    contracts: {
        StateCommitmentChain: string,
        CanonicalTransactionChain: string,
        BondManager: string,
        AddressManager: string,
        L1CrossDomainMessenger: string,
        L1StandardBridge: string,
        OptimismPortal: `0x${string}`,
        L2OutputOracle: string
    },
    bridges: {
        Standard: {
            l1Bridge: string,
            l2Bridge: string,
            Adapter: any
        },
        ETH: {
            l1Bridge: string,
            l2Bridge: string,
            Adapter: any
        }
    }
}

const Networks: Record<string, L2> = {
    goerlibase: {
        id: baseGoerli.id,
        rpcUrl: 'https://goerli.base.org',
        l1: {
            id: goerli.id,
        },
        contracts: {
            StateCommitmentChain: '0x0000000000000000000000000000000000000000',
            CanonicalTransactionChain: '0x0000000000000000000000000000000000000000',
            BondManager: '0x0000000000000000000000000000000000000000',
            AddressManager: '0x4Cf6b56b14c6CFcB72A75611080514F94624c54e',
            L1CrossDomainMessenger: '0x8e5693140eA606bcEB98761d9beB1BC87383706D',
            L1StandardBridge: '0xfA6D8Ee5BE770F84FC001D098C4bD604Fe01284a',
            OptimismPortal: '0xe93c8cD0D409341205A592f8c4Ac1A5fe5585cfA',
            L2OutputOracle: '0x2A35891ff30313CcFa6CE88dcf3858bb075A2298'
        },
        bridges: {
            Standard: {
                l1Bridge: '0xfA6D8Ee5BE770F84FC001D098C4bD604Fe01284a',
                l2Bridge: "0x4200000000000000000000000000000000000010",
                Adapter: optimismSDK.StandardBridgeAdapter
            },
            ETH: {
                l1Bridge: '0xfA6D8Ee5BE770F84FC001D098C4bD604Fe01284a',
                l2Bridge: "0x4200000000000000000000000000000000000010",
                Adapter: optimismSDK.ETHBridgeAdapter
            }
        }
    }
}

export { Networks }